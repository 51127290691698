
import { defineComponent } from 'vue'
import CopyClipboardComponent from '@/components/Common/CopyClipboardComponent.vue'

export default defineComponent({
  name: 'IOPageHeader',
  components: { CopyClipboardComponent },
  props: ['active', 'ioId', 'dsp', 'memberId', 'advertiserId'],
  data () {
    return {
      advertiserName: 'Advertiser name',
      memberName: 'Member name',
      ioName: 'IO name',
      isHoverMember: false,
      isHoverAdvertiser: false,
      isHoverIo: false
    }
  },
  methods: {
    emitClose () {
      this.$emit('close')
    },
    redirectToMember () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'insertionOrder' },
        query: { search: this.memberId }
      })
      this.emitClose()
    },
    redirectToAdvertiser () {
      this.$router.push({
        name: 'surcoucheV2',
        params: { dsp: this.dsp, tab: 'insertionOrder' },
        query: { search: this.advertiserId }
      })
      this.emitClose()
    },
    redirectToIoView () {
      this.$router.push({ name: 'surcoucheV2', params: { dsp: this.dsp, tab: 'insertionOrder' } })
      this.emitClose()
    },
    async callApi () {
      const response = await this.$apiCaller.getOverviewGroupKey(this.ioId, this.advertiserId, this.memberId)
      if (!this.$apiCaller.isResponseError(response)) {
        const data = (response as any).data
        this.advertiserName = data[0].advertiser.name
        this.memberName = data[0].member.name
        this.ioName = data[0].name
      } else {
        this.$store.commit('setErrorMessageWithResponse', response)
      }
    },
    openEdit () {
      this.$emit('edit')
    },
    openOutcomes () {
      this.$emit('outcomes')
    },
    openLogs () {
      this.$emit('logs')
    },
    openNotes () {
      this.$emit('notes')
    },
    openSearch () {
      this.$emit('search')
    }
  },
  watch: {
    active: function (value) {
      if (value) {
        this.callApi()
      }
    }
  }
})
