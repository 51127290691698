
import { defineComponent } from 'vue'
import IOPageHeader from '@/components/SurcoucheV2/IOPage/IOPageHeader.vue'

export default defineComponent({
  name: 'IOPage',
  components: { IOPageHeader },
  props: ['active', 'ioId', 'dsp', 'memberId', 'advertiserId'],
  methods: {
    emitClose () {
      this.$emit('close')
    }
  }
})
