
import { Localization, PixelIdFragment, AlgoStrategy, DSPOverwriting, ConstraintsToRespect, InfoIOFragments } from '@/components/TableComponents/Line/Fragments'
import RetryFastRetryFragment from '@/components/TableComponents/Line/Fragments/RetryFastRetryFragment.vue'
import InstructionGetterSetter from '@/models/Overlay/InstructionGetterSetter'
import RemoveScibidsModel from '@/models/RemoveScibids/RemoveScibidsModel'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LineIOV2',
  props: ['selected', 'props', 'dsp', 'dataIsLoading'],
  components: {
    RetryFastRetryFragment,
    Localization,
    AlgoStrategy,
    DSPOverwriting,
    PixelIdFragment,
    ConstraintsToRespect,
    InfoIOFragments
  },
  data: function () {
    return {
      checkBoxSelected: false,
      isHover: false,
      insGetterSetter: new InstructionGetterSetter(this.props.item, this.dsp)
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    selectAllInstructionsIO (io: any) {
      this.$emit('ask-select-item-and-kpi', this.props.item.id, this.props.item, this.checkBoxSelected)
      this.$emit('ask-select-instructions', this.props.item.id_list, this.checkBoxSelected)
    },
    selectInstructions: function (ids: any[] = [], state: boolean) {
      this.$emit('select-instructions', ids, state)
    },
    selectItemIdAndKpi: function (id: string, instruction: any, state: boolean) {
      this.$emit('select-item-and-kpi', id, instruction, state)
    },
    askForEditItem (item: string) {
      this.$emit('edit-item', item)
    },
    askForDuplicateIO (item: string) {
      this.$emit('duplicate-io', item)
    },
    askForOpenStrat (insertionOrderId: string) {
      this.$emit('open-strat', insertionOrderId)
    },
    removeScibids () {
      let removeScibidsData = {
        ioID: this.props.item.io,
        memberExtID: this.insGetterSetter.clientIdPerDsp,
        dsp: this.dsp,
        removeScibidsModel: new RemoveScibidsModel()
      }
      this.$emit('removeScibids', removeScibidsData)
    },
    openIo () {
      const data = {
        io: this.props.item.io,
        memberId: this.insGetterSetter.clientIdPerDsp,
        advertiserId: this.insGetterSetter.advertiserIdPerDsp,
        dsp: this.dsp
      }
      this.$emit('open-io', data)
    }
  },
  computed: {
  },
  watch: {

  }
})
