import { KpiValue } from '../../types/brief_enum'
import _ from 'lodash'
import { DspInstruction, InstructionDsp, IoBrief } from '../../types/instruction_type'

/**
 * A class who contain the business logic of the TrueKpi / classicKpi (proxy funnel) system.
 */
export class ThirdPartyKpiHelper {
  /**
   * A "tuple" like list. Each element is a list of two elements.
   * The first element is the key of the true KPI.
   * The second element is the key of the classic KPI (proxy funnel).
   */
  keyList: Array<[string, string]> = [
    ['true_conversion_funnel_pixel_ids', 'conversion_funnel_pixel_ids'],
    ['true_KPI_to_optimize', 'KPI_to_optimize'],
    ['true_conv_measurement_tool', 'conv_measurement_tool'],
    ['true_kpi_pixel_ids', 'kpi_pixel_ids'],
    ['objective.true_PV_window', 'objective.PV_window'],
    ['objective.true_PC_window', 'objective.PC_window'],
    ['obj_watcher.true_KPI_to_optimize.KPI', 'obj_watcher.KPI_to_optimize.KPI'],
    ['obj_watcher.true_KPI_to_optimize.value', 'obj_watcher.KPI_to_optimize.value'],
    ['obj_watcher.true_KPI_to_optimize.value_3rd_party', 'obj_watcher.KPI_to_optimize.value_3rd_party']
  ]

  secondLvlKpi = [
    KpiValue.authentic_attention_index,
    KpiValue.viewable_time,
    KpiValue.ROAS,
    KpiValue.LTV,
    KpiValue.engagement_index,
    KpiValue.exposure_index
  ]

  isKpiFirstLvl (kpiToOptimize: KpiValue) {
    return !this.isKpiSecondLvl(kpiToOptimize)
  }

  isKpiSecondLvl (kpiToOptimize: KpiValue) {
    return this.secondLvlKpi.includes(kpiToOptimize)
  }

  hasKpiLvlChanged (newKpi: KpiValue, oldKpi: KpiValue) {
    return this.isKpiFirstLvl(newKpi) !== this.isKpiFirstLvl(oldKpi)
  }

  getAllClassicKpiKey () {
    return this.keyList.map(key => key[1])
  }

  getAllTrueKpiKey () {
    return this.keyList.map(key => key[0])
  }

  processOnChangeKpiLvl (editedItem: IoBrief<DspInstruction>, dsp: InstructionDsp) {
    this.setDefaultValues(editedItem, dsp)
    this.manageTrueKpiValues(editedItem)
  }

  /**
   * When the KPI_to_optimize of the editedItem is a first level KPI, the 'classic' field
   * ('true_' field without the 'true_' at the beginning) should not be sent
   * to the api, because they will be synced with the 'true_' field.
   * @param editedItem {IoBrief<DspInstruction>} the KPI_to_optimize value of the editedItem
   */
  protected manageTrueKpiValues (editedItem: IoBrief<DspInstruction>) {
    const kpiToOptimize = editedItem.true_KPI_to_optimize
    if (this.isKpiFirstLvl(kpiToOptimize)) {
      const allClassicKeys = this.getAllClassicKpiKey()
      for (const key of allClassicKeys) {
        _.set(editedItem, key, null)
      }
    }
  }

  /**
   * In function if the KPI_to_optimize is 1st or 2nd level, some field should be set to a default value.
   * This function set the default values.
   * @param editedItem
   * @param dsp
   */
  protected setDefaultValues (editedItem: IoBrief<DspInstruction>, dsp: InstructionDsp) {
    const kpiToOptimize = editedItem.true_KPI_to_optimize
    if (this.isKpiSecondLvl(kpiToOptimize)) {
      if (kpiToOptimize === KpiValue.LTV) {
        editedItem.true_conv_measurement_tool = 'progressive'
      } else {
        editedItem.true_conv_measurement_tool = editedItem.true_conv_measurement_tool || 'doubleverify'
      }
      editedItem.conv_measurement_tool = editedItem.conv_measurement_tool || dsp
      editedItem.use_opti_ratio_surcouche = false
    }
  }
}
